import "./contact.scss";

const Contact = () => {
  return (
    <ul className="social">
      <li>
        <a
          aria-label="GitHub"
          href="https://github.com/chandez"
          rel="noreferrer"
          target="_blank"
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 22 22"
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 19.0268C3 20.5268 3 16.5268 1 16.0268M15 22.0268V18.1568C15.0375 17.68 14.9731 17.2006 14.811 16.7506C14.6489 16.3006 14.3929 15.8902 14.06 15.5468C17.2 15.1968 20.5 14.0068 20.5 8.54679C20.4997 7.15062 19.9627 5.80799 19 4.79679C19.4559 3.5753 19.4236 2.22514 18.91 1.02679C18.91 1.02679 17.73 0.676793 15 2.50679C12.708 1.88561 10.292 1.88561 8 2.50679C5.27 0.676793 4.09 1.02679 4.09 1.02679C3.57638 2.22514 3.54414 3.5753 4 4.79679C3.03013 5.81549 2.49252 7.17026 2.5 8.57679C2.5 13.9968 5.8 15.1868 8.94 15.5768C8.611 15.9168 8.35726 16.3222 8.19531 16.7667C8.03335 17.2112 7.96681 17.6849 8 18.1568V22.0268"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </a>
      </li>
      <li>
        <a
          href="https://www.linkedin.com/in/chandez"
          aria-label="LinkedIn"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 22 22"
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 2.43939C0 1.73231 0.247755 1.14898 0.743243 0.689394C1.23873 0.229791 1.88289 0 2.67568 0C3.45432 0 4.08429 0.226249 4.56564 0.678788C5.06113 1.14545 5.30888 1.75352 5.30888 2.50303C5.30888 3.18182 5.06822 3.74746 4.58687 4.2C4.09138 4.66667 3.44015 4.9 2.6332 4.9H2.61197C1.83333 4.9 1.20335 4.66667 0.722008 4.2C0.240662 3.73333 0 3.14646 0 2.43939ZM0.276062 21V6.8303H4.99035V21H0.276062ZM7.60232 21H12.3166V13.0879C12.3166 12.5929 12.3732 12.2111 12.4865 11.9424C12.6847 11.4616 12.9855 11.055 13.389 10.7227C13.7925 10.3904 14.2986 10.2242 14.9073 10.2242C16.4929 10.2242 17.2857 11.2919 17.2857 13.4273V21H22V12.8758C22 10.7828 21.5045 9.19545 20.5135 8.11364C19.5225 7.03182 18.213 6.49091 16.5849 6.49091C14.7587 6.49091 13.3359 7.27576 12.3166 8.84545V8.88788H12.2954L12.3166 8.84545V6.8303H7.60232C7.63062 7.28282 7.64479 8.68989 7.64479 11.0515C7.64479 13.4131 7.63062 16.7293 7.60232 21Z" />
          </svg>
        </a>
      </li>

      <li>
        <a
          aria-label="GitHub"
          href="https://twitter.com/chandez"
          rel="noreferrer"
          target="_blank"
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 22 22"
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M19.6878 1.89294C18.9636 2.21427 18.1858 2.4315 17.368 2.52914C18.2026 2.02936 18.842 1.23739 19.1439 0.294802C18.3511 0.765692 17.4834 1.0972 16.5786 1.27492C15.8423 0.490033 14.7922 0 13.6305 0C11.4007 0 9.59235 1.80765 9.59235 4.03876C9.59235 4.35488 9.62795 4.66329 9.6964 4.9594C6.33973 4.7907 3.36382 3.18282 1.37188 0.739637C1.02407 1.33633 0.824976 2.03009 0.824976 2.7703C0.824976 4.17133 1.53808 5.40746 2.62163 6.13157C1.98018 6.11169 1.35282 5.93855 0.791977 5.62662V5.67766C0.791977 7.63466 2.18393 9.26648 4.03167 9.6376C3.69224 9.7306 3.33603 9.77919 2.96752 9.77919C2.70696 9.77919 2.45418 9.75453 2.20723 9.70808C2.7212 11.3121 4.21277 12.4797 5.9796 12.5127C4.59735 13.5962 2.85564 14.2421 0.963958 14.2421C0.638138 14.2421 0.316124 14.2227 0 14.1846C1.78763 15.3308 3.91015 16 6.19105 16C13.6195 16 17.6822 9.84581 17.6822 4.50883C17.6822 4.33361 17.6784 4.1597 17.6699 3.98642C18.4615 3.41389 19.1447 2.705 19.6878 1.89294Z" />
          </svg>
        </a>
      </li>
      <li className="email">
        <a aria-label="email" href="mailto:chandez@gmail.com" rel="noreferrer">
          <svg
            width="40"
            height="40"
            viewBox="0 0 22 22"
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M19 1.25V13.75C19 14.4583 18.4854 15 17.8125 15H16.625V3.65583L9.5 9.04167L2.375 3.65583V15H1.1875C0.513792 15 0 14.4583 0 13.75V1.25C0 0.895833 0.12825 0.583333 0.341208 0.36C0.554167 0.133333 0.851833 0 1.1875 0H1.58333L9.5 6.04167L17.4167 0H17.8125C18.149 0 18.4458 0.135 18.6588 0.36C18.8725 0.583333 19 0.895833 19 1.25Z" />
          </svg>
        </a>
      </li>
    </ul>
  );
};

export default Contact;
